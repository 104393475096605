import React from 'react'
import './traits.css'
import {BiCheck} from 'react-icons/bi'
import {FaThumbsUp} from 'react-icons/fa'
import {FaThumbsDown} from 'react-icons/fa'

const Services = () => {
  return (
    <section id='Services'>
      <h5>My Work</h5>

      <div className="services">
        <h2>Experience</h2>
      </div>

      <div className="container services__container">
        <article className='service'>
          <div className='service__head'>
            <h3>Part-time Job | Logistics Associate at MPCM Inc. | San Jose, CA |</h3>
            <h3>Jan 2024 - Current</h3>
          </div>

          <ul className='service__list'>
            <li>
              {/* <FaThumbsUp className='service__list-icon'/> */}
              <p>Manage incoming and outgoing deliveries for Tesla and other automotive companies, both domestic and international, 
                overseeing all aspects of the logistics process. Maintain an up-to-date inventory list, ensuring accurate tracking and timely restocking. 
                Establish effective communication with clients to understand and meet their specific requirements, building strong professional relationships.
                </p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Customer Service</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Teamwork and Team Collaboration</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Utilize Microsoft Excel, Quickbooks</p>
            </li>

          </ul>
        </article>
        {/* END OF UI/UX */}
        {/* <article className='service'>
          <div className='service__head'>
            <h3>Weakness</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Public Speaking: Unusual stutter when speaking in public such 
                as a presentation or an interview. 
                Tend to be very nervous and lose track of thought process when 
                speaking nervously and causes speaking rapidly.</p>
            </li>

            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Lack of Experience in a specific area: Lack of knowledge in 
                some coding languages and 
                would usually struggle in critical thinking in coding 
                challenges. This leads to my strength curiosity where I'm willing to explore
                new skills beyond my limit.</p>
            </li>

            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Overthinking: Overthink about a task that must be done or something that is approaching a deadline. 
                This weakness leads to the strength determination.
                </p>
              
            </li>
          </ul>
        </article> */}
        {/* END OF WEB DEVELOPMENT */}
        {/* <article className='service'>
          <div className='service__head'>
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
          </ul>
        </article> */}
        {/* END OF CONTENT CREATION */}
      </div>
    </section>

  )
}

export default Services